export class TabsAutomatic {
  tablistNode: HTMLElement;
  tabs: HTMLElement[];
  tabpanels: HTMLElement[];
  firstTab: HTMLElement;
  lastTab: HTMLElement;

  constructor(groupNode: HTMLElement) {
    this.tablistNode = groupNode;

    this.tabs = Array.from(this.tablistNode.querySelectorAll("[role=tab]"));
    this.tabpanels = Array.from(
      this.tablistNode.nextElementSibling!.querySelectorAll("[role=tabpanel]")
    );

    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i];
      tab.tabIndex = -1;
      tab.setAttribute("aria-selected", "false");

      tab.addEventListener("keydown", this.onKeydown.bind(this));
      tab.addEventListener("click", this.onClick.bind(this));
    }

    this.firstTab = this.tabs[0];
    this.lastTab = this.tabs[this.tabs.length - 1];
    this.setSelectedTab(this.firstTab, false);
  }

  setSelectedTab(currentTab: HTMLElement, setFocus?: boolean) {
    if (setFocus !== false) {
      setFocus = true;
    }
    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i];
      if (currentTab === tab) {
        tab.setAttribute("aria-selected", "true");
        tab.removeAttribute("tabindex");
        this.tabpanels[i].classList.remove("rt-is-hidden");
        if (setFocus) {
          tab.focus();
        }
      } else {
        tab.setAttribute("aria-selected", "false");
        tab.tabIndex = -1;
        this.tabpanels[i].classList.add("rt-is-hidden");
      }
    }
  }

  setSelectedToPreviousTab(currentTab: HTMLElement) {
    if (currentTab === this.firstTab) {
      this.setSelectedTab(this.lastTab);
    } else {
      const index = this.tabs.indexOf(currentTab);
      this.setSelectedTab(this.tabs[index - 1]);
    }
  }

  setSelectedToNextTab(currentTab: HTMLElement) {
    if (currentTab === this.lastTab) {
      this.setSelectedTab(this.firstTab);
    } else {
      const index = this.tabs.indexOf(currentTab);
      this.setSelectedTab(this.tabs[index + 1]);
    }
  }

  /* EVENT HANDLERS */

  onKeydown(event: KeyboardEvent) {
    const tgt = event.currentTarget as HTMLElement;
    let flag = false;

    switch (event.key) {
      case "ArrowLeft":
        this.setSelectedToPreviousTab(tgt);
        flag = true;
        break;

      case "ArrowRight":
        this.setSelectedToNextTab(tgt);
        flag = true;
        break;

      case "Home":
        this.setSelectedTab(this.firstTab);
        flag = true;
        break;

      case "End":
        this.setSelectedTab(this.lastTab);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onClick(event: Event) {
    this.setSelectedTab(event.currentTarget as HTMLElement);
  }
}
