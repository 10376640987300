import "@retailtune/vanilla-jsx";
import {
  retailTuneAutocompleteHandler,
  createAutocompleteHandler,
  createOpeningHoursString,
  fetchUserDefaultPosition,
  positionToLatLngLiteral,
  createExpirationValue,
  USER_POSITION_CONSENT,
  sortStoresByPriority,
  getRadialDistanceFn,
  fetchUserPosition,
  createDebounceFn,
  createPosition,
  setExpirable,
  getExpirable,
  Trap_focus,
  getDevice,
  api,
} from "@retailtune/utils";
import {
  createToastMessagesContainer,
  createScrollButton,
  ARIA_Autocomplete,
  createSidebar,
} from "@retailtune/vanilla-ui-core";
import { Service, Store } from "@retailtune/types/lib/store";
import { Position } from "@retailtune/types/lib/geolocation";
import {
  AnalyticsCategory,
  AnalyticsAction,
} from "@retailtune/types/lib/analytics";
import { UrlItem } from "@retailtune/types/lib/storelocator";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Expirable } from "@retailtune/types/lib/local-storage";
import {
  googleAutocompleteHandler,
  CustomRenderer,
} from "@retailtune/google-maps-utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Translations } from "../common/translations";
import { mapsStyles } from "../common/mapStyles";
import { TabsAutomatic } from "../common/tabs";

// ______________ TYPE DEFINITIONS ______________

declare const retailtune: {
  urlPrefix: string;
  api_key: string;
  language: string;
  translations: Translations;
};

// Analytics
declare function sendGa4DL(
  category: AnalyticsCategory,
  action: AnalyticsAction,
  label: string,
  storeId: number
): void;

interface Positions {
  current: Position;
  user: Position;
  default: Position;
}

interface Directions {
  origin: google.maps.LatLngLiteral;
  destination: {
    storeName: string;
    link: string;
    latlngLitteral: google.maps.LatLngLiteral;
  } | null;
  travelMode: google.maps.TravelMode;
}

interface Filterobj {
  name: string;
  filterParam: string;
  icon: string;
  filterGroup: string;
}

interface Filterstate {
  [key: string]: Filterobj[];
}

// ______________ GLOBAL VARIABLES ______________

let appliedFilters: Filterobj[] = [];
let filterObject: Filterstate = {};
let deviceType = getDevice();

let storesArray: (Store & { storeLinkBooking: UrlItem })[];
let filteredStores: (Store & { storeLinkBooking: UrlItem })[] = [];
let visibleStores: (Store & { storeLinkBooking: UrlItem })[];
let servicesArray: Service[];

let storeCountriesSet = new Set<string>();
let storeTypeIdSet = new Set<string>();
let storeTypeNameSet = new Set<string>();
let storeIdToMarkerMap = new Map<number, google.maps.Marker>();
let directionsPaneTrapFocusObj: Trap_focus;
let sidebarTrapFocusObj: Trap_focus;
let consentModalFocusObj: Trap_focus;
let originAutocompleteObj: ARIA_Autocomplete<PredictionData>;
let mainAutocompleteObj: ARIA_Autocomplete<PredictionData>;
let brandTabListInstance: TabsAutomatic;

let positions: Positions;
let directions: Directions;
let travelModes: google.maps.TravelMode[];

let map: google.maps.Map;
let markersArray: google.maps.Marker[];
let infoWindow: google.maps.InfoWindow;
let directionsRendererObj: google.maps.DirectionsRenderer;
let googleDirectionsService: google.maps.DirectionsService;
let markerClustererObj: MarkerClusterer;
let destinationMarker: google.maps.Marker;
let userMarker: google.maps.Marker;

let toggleSidebar: (shouldShow: boolean) => void;
let updateSideBarContent: (newContent: HTMLElement) => void;
let toast: (message: string) => void;

async function main() {
  const { api_key, language, urlPrefix, translations } = retailtune;

  // ______________ APPLICATION SET-UPS ______________
  const [pageData, _defaultPosition] = await Promise.all([
    api.fetchPageData(api_key, language),
    fetchUserDefaultPosition(api_key),
  ]);

  storesArray = pageData.stores;
  filteredStores = pageData.stores;
  servicesArray = pageData.services;

  positions = {
    default: _defaultPosition,
    user: _defaultPosition,
    current: _defaultPosition,
  };

  const mapContainer = document.getElementById("rt_map")!;

  map = new google.maps.Map(mapContainer, {
    center: positionToLatLngLiteral(positions.user),
    zoom: 10,
    styles: mapsStyles,
  });

  const mapDebounceFunc = createDebounceFn()(200);
  map.addListener("bounds_changed", () => {
    mapDebounceFunc(() => {
      createStoreCards();
    });
  });

  infoWindow = new google.maps.InfoWindow();

  for (let store of storesArray) {
    if (store.status === "closed") {
      continue;
    }

    const storePosition = {
      lat: store.latitude,
      lng: store.longitude,
    };

    const marker = new google.maps.Marker({
      position: storePosition,
      icon: `${urlPrefix}/img/pin/pin-store.svg`,
    });

    marker.addListener("click", () => {
      infoWindow.setContent(getInfoWindow(store));
      infoWindow.open({
        anchor: marker,
        map,
      });

      // Analytics
      sendGa4DL(
        "Store",
        "Click",
        `StoreClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
        store.id
      );
    });

    storeCountriesSet.add(store.country.alpha2);
    storeTypeNameSet.add(store.storeTypes[0].name);
    storeTypeIdSet.add(store.storeTypes[0].id);
    storeIdToMarkerMap.set(store.id, marker);
  }
  markersArray = Array.from(storeIdToMarkerMap.values());

  // objects construction for filter sidebar creation
  const storeTypeNameArray = Array.from(storeTypeNameSet);
  filterObject[`${translations.k_tipology}`] = Array.from(storeTypeIdSet).map(
    (st, index) => {
      return {
        filterParam: st,
        icon: "",
        name: storeTypeNameArray[index],
        filterGroup: "tipology",
      };
    }
  );

  servicesArray.forEach((service) => {
    if (filterObject[service.groupName]) {
      filterObject[service.groupName].push({
        name: service.name,
        icon: service.icon,
        filterParam: service.id.toString(),
        filterGroup: service.groupName,
      });
    } else {
      filterObject[service.groupName] = [
        {
          name: service.name,
          icon: service.icon,
          filterParam: service.id.toString(),
          filterGroup: service.groupName,
        },
      ];
    }
  });

  markerClustererObj = new MarkerClusterer({
    map,
    markers: markersArray,
    renderer: new CustomRenderer({
      clusterIcon: `${urlPrefix}/img/pin/cluster.svg`,
    }),
  });

  userMarker = new google.maps.Marker({
    map,
    position: positionToLatLngLiteral(positions.current),
    icon: `${urlPrefix}/img/pin/pin-user.svg`,
  });

  googleDirectionsService = new google.maps.DirectionsService();
  directionsRendererObj = new google.maps.DirectionsRenderer({
    markerOptions: {
      visible: false,
    },
  });

  travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.TRANSIT,
  ];

  directions = {
    origin: positionToLatLngLiteral(positions.user),
    travelMode: google.maps.TravelMode.DRIVING,
    destination: null,
  };

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  travelModesContainer.replaceChildren(
    <>
      {travelModes.map((travelMode) => {
        const travelModeBtnEl: HTMLElement = (
          <button
            id={`rt_travel_mode_${travelMode.toLowerCase()}_btn`}
            aria-label={travelMode.toLowerCase()}
            class={`rt-travel-mode ${
              directions.travelMode === travelMode
                ? "rt-travel-mode--selected"
                : ""
            }`}
            onclick={async (e: Event) => {
              const oldTravelMode = directions.travelMode;
              directions.travelMode = travelMode;
              const currentTravelModeBtn = e.currentTarget as HTMLButtonElement;
              for (const travelModeBtn of travelModesContainer.children) {
                travelModeBtn.classList.remove("rt-travel-mode--selected");
              }
              currentTravelModeBtn.classList.add("rt-travel-mode--selected");

              const ssuccessResult = await navigateUser();
              if (!ssuccessResult) {
                directions.travelMode = oldTravelMode;
                const oldTravelModeBtn = document.getElementById(
                  `rt_travel_mode_${oldTravelMode.toLowerCase()}_btn`
                )!;
                for (const travelModeBtn of travelModesContainer.children) {
                  travelModeBtn.classList.remove("rt-travel-mode--selected");
                }
                oldTravelModeBtn.classList.add("rt-travel-mode--selected");
              }
            }}
          >
            <img
              class="rt-travel-mode__icon"
              src={`/img/icon/${travelMode.toLowerCase()}-inactive.svg`}
              alt="travel mode icon"
            />
            <img
              class="rt-travel-mode__icon"
              src={`/img/icon/${travelMode.toLowerCase()}-active.svg`}
              alt="travel mode icon"
            />
          </button>
        );
        travelModeBtnEl.setAttribute("travelMode", travelMode);
        return travelModeBtnEl;
      })}
    </>
  );

  // ______________ LoCaL cOmPoNeNt ______________

  mainAutocompleteObj = new ARIA_Autocomplete({
    anchor: "rt_main_autocomplete",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (perdictionData: PredictionData) => {
        if (directions.destination) {
          clearDrivingDirections();
        }

        positions.current = createPosition({
          latitude: perdictionData.latitude,
          longitude: perdictionData.longitude,
        });

        const predictionLatLngLitteral = {
          lat: perdictionData.latitude,
          lng: perdictionData.longitude,
        };

        userMarker.setPosition(predictionLatLngLitteral);
        map.setCenter(predictionLatLngLitteral);

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
      },
    },
    id: "main",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
  });

  // store list SCT button
  createScrollButton({
    anchorEl: "rt_list_container",
    scrollingEl: "rt_list_container",
    id: "rt_store_list_scroller",
  });

  // directions pane SCT button
  createScrollButton({
    anchorEl: "rt_directions_pane",
    scrollingEl: "rt_directions_pane",
    id: "rt_directions_pane_scroller",
  });

  originAutocompleteObj = new ARIA_Autocomplete({
    anchor: "rt_autocomplete_origin",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (perdictionData: PredictionData) => {
        directions.origin = {
          lat: perdictionData.latitude,
          lng: perdictionData.longitude,
        };
        navigateUser();

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
      },
    },
    id: "origin",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_homepage",
    position: "top-right",
  });

  [toggleSidebar, updateSideBarContent] = createSidebar({
    position: "right",
    anchor: "rt_homepage",
    content: getSideBarContent(),
  });

  // setting directions pane focus obj to trap/untrap focus when needed
  const directionsPaneContainer =
  document.getElementById("rt_directions_pane")!;
  directionsPaneTrapFocusObj = new Trap_focus(
    directionsPaneContainer,
    "rt_skip_link"
  );
  
  // setting directions pane's focus obj to trap/untrap focus when needed
  const filtersSidebarContEl = document.getElementById("rt_filters_sidebar")!;
  sidebarTrapFocusObj = new Trap_focus(filtersSidebarContEl, "rt_skip_link");
  
  // setting consent modal's focus obj to trap/untrap focus when needed
  const consentModalContEl = document.getElementById("rt_position_consent_modal")!;
  consentModalFocusObj = new Trap_focus(consentModalContEl);

  // ______________ DOM MODIFCATIONS ______________

  const filterBtn = document.getElementById("rt_btn_filters")!;
  filterBtn.onclick = () => {
    toggleSidebar(true);
    setTimeout(() => sidebarTrapFocusObj.trap(), 100);
  };

  const directionsPaneCloseBtn = document.getElementById(
    "rt_directions_pane_btn_close"
  )!;
  directionsPaneCloseBtn.onclick = () => {
    clearDrivingDirections();

    setTimeout(() => directionsPaneTrapFocusObj.untrap(), 100);
  };

  const backToNearestStoreBtn = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  backToNearestStoreBtn.onclick = (e: Event) => {
    if (filteredStores.length === 0) {
      return map.setZoom(1);
    }
    do {
      let currentZoom = map.getZoom()!;
      map.setZoom(--currentZoom);
      createStoreCards();
    } while (visibleStores.length === 0);
  };

  const findClosestStore = document.getElementById("rt_find_closest_store")!;
  findClosestStore.onclick = async () => {
    clearDrivingDirections();
    geolocateUser();
    // Analytics
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const positionConsentModal = document.getElementById(
    "rt_position_consent_modal"
  ) as HTMLDialogElement;

  const consentModalCloseBtn = document.getElementById(
    "rt_consent_modal_close_btn"
  )!;
  consentModalCloseBtn.onclick = () =>
    showConsentModal(false, positionConsentModal);

  const positionConsentModalRejectionBtn = document.getElementById(
    "rt_position_consent_modal_rejection"
  )!;
  positionConsentModalRejectionBtn.onclick = () => {
    showConsentModal(false, positionConsentModal);
    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };

  const positionConsentModalAgreementBtn = document.getElementById(
    "rt_position_consent_modal_agreement"
  )!;
  positionConsentModalAgreementBtn.onclick = () => {
    setExpirable(USER_POSITION_CONSENT, {
      value: "true",
      expiration: createExpirationValue(1, "years"),
    });
    geolocateUser();

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  window.onresize = () => {
    const curentDeviceType = getDevice();
    // condition refers to when directions service is active and device is switched from desktop to mobile/tablet.
    if (
      directions.destination &&
      deviceType === "desktop" &&
      curentDeviceType !== "desktop"
    ) {
      deviceType = getDevice();
      // reinitialize directions service
      initDrivingDirections();
      clearDrivingDirections();
    }
  };

  // * --------------------------------------------------------------------------------------
  // * initialization of the tabs section
  // * --------------------------------------------------------------------------------------

  const tablist = document.querySelector<HTMLElement>("[role=tablist]")!;
  brandTabListInstance = new TabsAutomatic(tablist);

  await geolocateUser();

  const destinationStoreCode = new URLSearchParams(window.location.search).get(
    "code"
  );
  const destinationStore = storesArray.find(
    (store) => store.storeCode === destinationStoreCode
  );
  if (destinationStore) {
    const storePosition = {
      lat: destinationStore.latitude,
      lng: destinationStore.longitude,
    };
    directions.destination = {
      latlngLitteral: storePosition,
      link: destinationStore.googleMapsLink,
      storeName: destinationStore.name,
    };
    initDrivingDirections();
  }
}

async function geolocateUser() {
  if (getExpirable<Expirable>(USER_POSITION_CONSENT)) {
    positions.user = await fetchUserPosition(positions.default);

    switch (positions.user.type) {
      case "html5":
        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    const userPosition = positionToLatLngLiteral(positions.user);
    directions.origin = userPosition;

    map.setZoom(12);
    map.setCenter(userPosition);
    userMarker.setPosition(userPosition);
    // close modal
    showConsentModal(false);
  } else {
    // show modal
    showConsentModal(true);
  }
}

function showConsentModal(shouldShow: boolean, modalEl?: HTMLDialogElement) {
  if (!modalEl) {
    modalEl = document.getElementById(
      "rt_position_consent_modal"
    )! as HTMLDialogElement;
  }
  if (shouldShow) {
    modalEl.showModal();
    consentModalFocusObj.trap();
  } else {
    modalEl.close();
    consentModalFocusObj.untrap();
  }
}

function initDrivingDirections() {
  if (getDevice() !== "desktop") {
    window.open(directions.destination!.link, "_blank");
    return;
  }

  // opening directions pane
  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneContainer.classList.add("rt-directions-pane--visible");

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  for (const travelModeBtn of travelModesContainer.children) {
    travelModeBtn.classList.remove("rt-travel-mode--selected");
    if (travelModeBtn.getAttribute("travelMode") === directions.travelMode) {
      travelModeBtn.classList.add("rt-travel-mode--selected");
    }
  }

  // switch scroll to top component
  const storeListScroller = document.getElementById("rt_store_list_scroller")!;
  storeListScroller.classList.remove("rt-back-to-top--visible");

  // destination label change
  const instructionsLableSpan = document.getElementById(
    "rt_instructions_label"
  )!;
  instructionsLableSpan.textContent = directions.destination!.storeName;

  // address destination label change
  const addressDestinationSpan = document.getElementById(
    "rt_address_destination"
  )!;
  addressDestinationSpan.textContent = directions.destination!.storeName;

  // trapping focus inside directions pane
  setTimeout(() => directionsPaneTrapFocusObj.trap(), 100);

  destinationMarker = new google.maps.Marker({
    position: directions.destination
      ? directions.destination.latlngLitteral
      : null,
    icon: `${retailtune.urlPrefix}/img/pin/pin-store.svg`,
  });

  markerClustererObj.clearMarkers();
  markerClustererObj.addMarker(destinationMarker);

  navigateUser();
}

async function navigateUser(): Promise<boolean | void> {
  if (!directions.destination) {
    return;
  }

  let navigationResultSuccess = true;

  userMarker.setPosition(directions.origin);

  // initialize google maps directions API
  try {
    const directionsResultObj = await googleDirectionsService.route({
      origin: directions.origin,
      destination: directions.destination.latlngLitteral,
      travelMode: directions.travelMode,
      language: retailtune.language,
    });

    const instructionsList = document.getElementById("rt_instructions_list")!;
    instructionsList.replaceChildren(
      <>
        {directionsResultObj.routes[0].legs[0].steps.map((step, index) => (
          <li tabIndex="0" class="rt-instructions-item">
            <div>
              <strong>{++index}</strong>
              <span innerHTML={step.instructions}></span>
            </div>
          </li>
        ))}
      </>
    );

    directionsRendererObj.setOptions({
      directions: directionsResultObj,
      map,
    });
  } catch (e) {
    toast(retailtune.translations.k_navigation_toast_message);
    navigationResultSuccess = false;
  }
  return navigationResultSuccess;
}

function getInfoWindow(
  store: Store & { storeLinkBooking: UrlItem }
): HTMLElement {
  const { language, urlPrefix, translations } = retailtune;
  return (
    <article class="rt-iw">
      {store.status === "will_open_soon" && (
        <span class="rt-iw__next-opening | rt-next-opening">
          {translations.k_opening_soon}
        </span>
      )}
      <span class="rt-iw__name  ">{store.name}</span>
      <div class="rt-iw__info">
        <span class="rt-iw__address">
          {store.address1},{store.postalCode}, {store.city},{" "}
          {store.province.code}
        </span>
        <ul class="rt-iw__contacts">
          {store.phone && (
            <li>
              <a
                href={`tel:${store.phone}`}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `PhoneClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                <img
                  aria-hidden="true"
                  src={`${urlPrefix}/img/icon/phone.svg`}
                  alt="phone icon"
                  width="9"
                  height="15"
                />
                <span>{store.phone}</span>
              </a>
            </li>
          )}
          {store.whatsapp && (
            <li>
              <a
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `WhatsappClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
                // whatsApp number normalization
                href={`https://api.whatsapp.com/send?phone=${store.whatsapp
                  .replace(/^\+/, "00")
                  .replace(/[\s_-]+/g, "")}`}
              >
                <img
                  aria-hidden="true"
                  src={`${urlPrefix}/img/icon/whatsapp.svg`}
                  alt="whatsapp icon"
                  width="9"
                  height="15"
                />
                <span>{translations.k_chat_now}</span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div class="rt-iw__cta">
        <a
          class="rt-iw__details | rt-btn rt-btn--primary"
          href={urlPrefix + "/" + language + store.storeLink.path}
        >
          {translations.k_info_and_promotions}
        </a>
        <button
          class="rt-iw__directions | rt-btn rt-btn--secondary"
          onclick={() => {
            const storePosition = {
              lat: store.latitude,
              lng: store.longitude,
            };
            directions.destination = {
              latlngLitteral: storePosition,
              link: store.googleMapsLink,
              storeName: store.name,
            };
            initDrivingDirections();

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `DirectionsClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          {translations.k_bring_me_here}
        </button>
        {/* // TODO to be done */}
        {/* {store.storeLinkBooking.path.length !== 0 && (
          <a
            class="rt-iw__booking | rt-btn rt-btn--tertiary"
            href={`${urlPrefix}/${language}${store.storeLinkBooking.path}/booking.php?ctaRT=${store.storeCode}-${language}`}
          >
            {translations.k_book_appointment}
          </a>
        )} */}
      </div>
    </article>
  );
}

function filterStores() {
  if (appliedFilters.length === 0) {
    return resetFilter();
  }

  if (directions.destination) {
    clearDrivingDirections();
  }

  filteredStores = [];
  markersArray = [];
  markerClustererObj.clearMarkers();

  let serviceIsSelected = false;
  let tipologyIsSelected = false;
  let tipologyAndServiceAreSelected = false;
  appliedFilters.forEach((af) => {
    if (af.filterGroup === "tipology") {
      tipologyIsSelected = true;
    } else {
      serviceIsSelected = true;
    }
  });

  if (serviceIsSelected && tipologyIsSelected) {
    tipologyAndServiceAreSelected = true;
  }

  if (tipologyAndServiceAreSelected) {
    storesArray.forEach((store) => {
      if (
        appliedFilters.some(
          (af) => af.filterParam === store.storeTypes[0].id
        ) &&
        appliedFilters.some((af) =>
          store.serviceIds.some((si) => +af.filterParam === si)
        )
      ) {
        filteredStores.push(store);
        markersArray.push(storeIdToMarkerMap.get(store.id)!);
      }
    });
  } else {
    storesArray.forEach((store) => {
      if (tipologyIsSelected) {
        if (
          appliedFilters.some((af) => af.filterParam === store.storeTypes[0].id)
        ) {
          filteredStores.push(store);
          markersArray.push(storeIdToMarkerMap.get(store.id)!);
        }
      } else {
        if (
          appliedFilters.some((af) =>
            store.serviceIds.some((si) => +af.filterParam === si)
          )
        ) {
          filteredStores.push(store);
          markersArray.push(storeIdToMarkerMap.get(store.id)!);
        }
      }
    });
  }
  markerClustererObj.addMarkers(markersArray);
  createStoreCards();
}

function resetFilter() {
  appliedFilters = [];
  filteredStores = storesArray;
  markersArray = Array.from(storeIdToMarkerMap.values());
  markerClustererObj.addMarkers(markersArray);
  createStoreCards();
}

function createStoreCards() {
  const { language, translations, urlPrefix } = retailtune;
  visibleStores = [];
  const mapBounds = map.getBounds()!;

  for (let i = 0; i < filteredStores.length; ++i) {
    if (filteredStores[i].status === "closed") {
      continue;
    }

    const storePosition = {
      lat: filteredStores[i].latitude,
      lng: filteredStores[i].longitude,
    };

    const getDistance = getRadialDistanceFn(
      positions.current.latitude,
      positions.current.longitude
    );

    if (mapBounds.contains(storePosition)) {
      filteredStores[i].distance = getDistance(
        filteredStores[i].latitude,
        filteredStores[i].longitude
      );
      visibleStores.push(filteredStores[i]);
    }
  }

  visibleStores.sort(sortStoresByPriority);

  const backToNearestStoreBtn = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  if (visibleStores.length === 0) {
    backToNearestStoreBtn.classList.add("rt-back-to-nearest-store--visible");
  } else {
    backToNearestStoreBtn.classList.remove("rt-back-to-nearest-store--visible");
  }

  const storeCardsList = document.getElementById("rt_store_list")!;
  storeCardsList.replaceChildren(
    <>
      {visibleStores.map((store) => (
        <li
          onclick={() => {
            const storePosition = {
              lat: store.latitude,
              lng: store.longitude,
            };

            infoWindow.setContent(getInfoWindow(store));
            infoWindow.setPosition(storePosition);
            infoWindow.open({
              map,
              anchor: storeIdToMarkerMap.get(store.id),
            });

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `StoreClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          <article tabIndex="0" class="rt-store-card">
            {store.status === "will_open_soon" && (
              <span class="rt-store-card__next-opening | rt-next-opening">
                {translations.k_opening_soon}
              </span>
            )}
            <div class="rt-store-card__heading">
              <span class="rt-store-card__name">{store.name}</span>
            </div>
            <div class="rt-store-card__info">
              <span class="rt-store-card__address">
                {store.address1}, {store.postalCode}, {store.city},{" "}
                {store.province.code}
              </span>
              <ul class="rt-store-card__contacts">
                {store.phone && (
                  <li>
                    <a
                      href={`tel:${store.phone}`}
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `PhoneClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                          store.id
                        );
                      }}
                    >
                      <img
                        aria-hidden="true"
                        src="/img/icon/phone.svg"
                        alt="phone icon"
                        width="9"
                        height="15"
                      />
                      <span>{store.phone}</span>
                    </a>
                  </li>
                )}
                {store.whatsapp && (
                  <li>
                    <a
                      href={`https://wa.me/:${store.whatsapp}`}
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `WhatsappClickMap-${store.city} ${store.address1}-${deviceType}-${retailtune.language}`,
                          store.id
                        );
                      }}
                    >
                      <img
                        aria-hidden="true"
                        src="/img/icon/whatsapp.svg"
                        alt="whatsapp icon"
                        width="9"
                        height="15"
                      />
                      <span>{translations.k_chat_now}</span>
                    </a>
                  </li>
                )}
              </ul>
              <span
                class="rt-store-card__hours-today"
                innerHTML={createOpeningHoursString(
                  language,
                  store,
                  translations.k_store_closed
                )}
              ></span>
            </div>
            <div class="rt-store-card__cta">
              <a
                class="rt-store-card__details | rt-btn rt-btn--primary"
                href={urlPrefix + "/" + language + store.storeLink.path}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DetailsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {translations.k_info_and_promotions}
              </a>
              <button
                class="rt-store-card__directions | rt-btn rt-btn--secondary"
                onclick={(e: Event) => {
                  e.stopPropagation();
                  const storePosition = {
                    lat: store.latitude,
                    lng: store.longitude,
                  };
                  directions.destination = {
                    latlngLitteral: storePosition,
                    link: store.googleMapsLink,
                    storeName: store.name,
                  };
                  initDrivingDirections();

                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DirectionsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {translations.k_bring_me_here}
              </button>
              {/* {store.storeLinkBooking.path.length > 0 && (
                <a
                  class="rt-store-card__booking | rt-btn rt-btn--tertiary"
                  href="#"
                  onclick={(e: Event) => {
                    e.stopPropagation();
                    // Analytics
                    sendGa4DL(
                      "Store",
                      "Click",
                      ``,
                      // TODO anche qua
                      store.id
                    );
                  }}
                >
                  {translations.k_book_appointment}
                </a>
              )} */}
            </div>
          </article>
        </li>
      ))}
    </>
  );
}

function clearDrivingDirections() {
  const userPositionLatLngLitteral = positionToLatLngLiteral(positions.user);

  directions = {
    origin: userPositionLatLngLitteral,
    destination: null,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  // switching back scroll to top component
  const directionsPaneScroller = document.getElementById(
    "rt_directions_pane_scroller"
  )!;
  directionsPaneScroller.classList.remove("rt-back-to-top--visible");

  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneContainer.classList.remove("rt-directions-pane--visible");

  originAutocompleteObj.resetAutocomplete();
  mainAutocompleteObj.resetAutocomplete();

  directionsRendererObj.setMap(null);
  markersArray = Array.from(storeIdToMarkerMap.values());
  markerClustererObj.addMarkers(markersArray);
}

function getSideBarContent(): HTMLElement {
  const { translations } = retailtune;
  return (
    <div id="rt_filters_sidebar" class="rt-filters">
      <header class="rt-filters__header">
        <h2 class="rt-filters__heading">{translations.k_filter}</h2>
        <button
          onclick={() => {
            toggleSidebar(false);
            resetFilter();
            updateSideBarContent(getSideBarContent());
            const tablist =
              document.querySelector<HTMLElement>("[role=tablist]")!;
            brandTabListInstance = new TabsAutomatic(tablist);
            // untrap the focus from the sidebar container to the skipLink el
            setTimeout(() => sidebarTrapFocusObj.untrap(), 100);
          }}
          class="rt-filters__reset"
        >
          {translations.k_cancel_filters}
        </button>
        <button
          onclick={() => {
            toggleSidebar(false);
            // untrap the focus from the sidebar container to the skipLink el
            setTimeout(() => sidebarTrapFocusObj.untrap(), 100);
          }}
          class="rt-btn-close"
        >
          <img
            aria-hidden="true"
            src="/img/icon/cross.svg"
            alt="close icon"
            width="16"
            height="16"
          />
        </button>
      </header>
      <div id="rt_filters_body_container" class="rt-filters__body">
        {appliedFilters.length > 0 && getAppliedFiltersSectionUI(translations)}
        <ul id="rt_filters_categories_list" class="rt-filters__categories">
          {Object.keys(filterObject).map((filterGroup, accordionIndex) => (
            <li class="rt-filters__category">
              <div class="rt-accordion">
                <button
                  type="button"
                  aria-expanded={appliedFilters.some(
                    (af) => af.filterGroup === filterGroup
                  )}
                  class="rt-accordion-trigger"
                  aria-controls={`rt_accordion_panel_filters_${++accordionIndex}`}
                  id={`rt_accordion_trigger_filters_${accordionIndex}`}
                  onclick={(e: Event) => {
                    e.stopPropagation();
                    const currentEl = e.currentTarget as HTMLButtonElement;
                    currentEl.ariaExpanded =
                      currentEl.ariaExpanded === "true" ? "false" : "true";
                    const accordionPanel =
                      currentEl.nextSibling as HTMLDivElement;
                    accordionPanel.toggleAttribute("hidden");
                  }}
                >
                  <span class="rt-accordion-title">
                    <span
                      class="rt-filters__title"
                      data-selected-options={
                        appliedFilters.filter(
                          (af) => af.filterGroup === filterGroup
                        ).length
                      }
                    >
                      {filterGroup}
                    </span>
                    <img
                      class="rt-accordion-icon"
                      src="/img/icon/chevron.svg"
                      alt="chevron icon"
                      aria-hidden="true"
                    />
                  </span>
                </button>
                <div
                  id={`rt_accordion_panel_filters_${accordionIndex}`}
                  role="region"
                  aria-labelledby={`rt_accordion_trigger_filters_${accordionIndex}`}
                  class="rt-accordion-panel"
                  hidden={
                    appliedFilters.some((af) => af.filterGroup === filterGroup)
                      ? false
                      : true
                  }
                >
                  {filterGroup === "Brand" ? (
                    getBrandFinder(filterObject[`Brand`])
                  ) : (
                    <div>
                      <ul>
                        {filterObject[`${filterGroup}`].map(
                          (filterCategory) => (
                            <li>
                              <label class="rt-filters__option">
                                <input
                                  id={`rt_filter_item_${filterCategory.filterParam}`}
                                  type="checkbox"
                                  onclick={(e: Event) => {
                                    const currentInput =
                                      e.currentTarget as HTMLInputElement;
                                    if (currentInput.checked) {
                                      // adding the filter to the appliedFilters state and rerendering the UI
                                      appliedFilters.push(filterCategory);
                                    } else {
                                      // remove the filter from the appliedFilters state and rerendering the UI
                                      appliedFilters = appliedFilters.filter(
                                        (af) =>
                                          af.filterParam !==
                                          filterCategory.filterParam
                                      );
                                    }
                                    filterStores();
                                    updateAppliedFiltersSectionUI();
                                  }}
                                  checked={appliedFilters.some(
                                    (af) =>
                                      af.filterParam ===
                                      filterCategory.filterParam
                                  )}
                                />
                                <span>{filterCategory.name}</span>
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <footer class="rt-filters__footer">
        <button
          onclick={() => {
            toggleSidebar(false);
            // untrap the focus from the sidebar container to the skipLink el
            setTimeout(() => sidebarTrapFocusObj.untrap(), 100);
          }}
          class="rt-filters__show-results | rt-btn rt-btn--primary"
        >
          {translations.k_show_stores}
        </button>
      </footer>
    </div>
  );
}

function getAppliedFiltersSectionUI(translations: Translations) {
  return (
    <div id="rt_applied_filters_container" class="rt-filters__applied-filters">
      <span
        class="rt-filters__title"
        data-selected-options={appliedFilters.length}
      >
        {translations.k_applied_filters}
      </span>
      <ul id="rt_applied_filters_list">
        {appliedFilters.map((appliedFilter) => (
          <li>
            <span class="rt-filters__applied-filter">
              {appliedFilter.name}
              <button
                onclick={(e: Event) => {
                  e.stopPropagation();
                  appliedFilters = appliedFilters.filter(
                    (filter) => filter.filterParam !== appliedFilter.filterParam
                  );
                  filterStores();
                  updateAppliedFiltersSectionUI();

                  // updating checkboxes selection
                  const filtersBodyContainer = document.getElementById(
                    "rt_filters_body_container"
                  )!;
                  const filterInputs =
                    filtersBodyContainer.querySelectorAll<HTMLInputElement>(
                      `#rt_filter_item_${appliedFilter.filterParam}`
                    );
                  filterInputs.forEach(
                    (checkbox) =>
                      (checkbox.checked = checkbox.checked ? false : true)
                  );
                }}
                class="rt-btn-close"
              >
                <img
                  aria-hidden="true"
                  src="/img/icon/cross.svg"
                  alt="close icon"
                  width="10"
                  height="10"
                />
              </button>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function checkBoxEventHandler(
  currentInput: HTMLInputElement,
  filterCategory: Filterobj
) {
  if (currentInput.checked) {
    // adding the filter to the appliedFilters state and rerendering the UI
    appliedFilters.push(filterCategory);
  } else {
    // remove the filter from the appliedFilters state and rerendering the UI
    appliedFilters = appliedFilters.filter(
      (af) => af.filterParam !== filterCategory.filterParam
    );
  }
  filterStores();
  updateAppliedFiltersSectionUI();
}

function getBrandFinder(brandObjArray: Filterobj[]) {
  let alphabetLetters = [...Array(26)].map((_, i) => {
    const letter = String.fromCharCode(i + 65);
    return [new RegExp(`^${letter}.*`, "gm"), letter];
  });
  alphabetLetters.push([new RegExp("^[^a-zA-Z]", "gm"), "0-9"]);
  return (
    <div class="rt-brands">
      <div class="rt-brands__search-area">
        <div class="rt-brands__search-input">
          <input
            type="search"
            placeholder="Cerca il brand"
            onkeyup={(e: Event) => {
              const brandFreeSearchInput = e.currentTarget as HTMLInputElement;
              // if user cleaned the input, we hide all tabpanels
              // and simulate a click on the first tab
              if (brandFreeSearchInput.value === "") {
                for (const tabPanel of brandTabListInstance.tabpanels)
                  tabPanel.classList.add("rt-is-hidden");
                // simulate click on first tab
                brandTabListInstance.tablistNode
                  .querySelector("[role='tab']")!
                  .dispatchEvent(new Event("click"));
                brandTabListInstance.tablistNode.style.display = "flex";
                // Reseting the the active element
                brandFreeSearchInput.focus();
              }
              // else, if the input has some characters in it, we hide all tabpanels
              // and we show only the free search one
              else {
                for (const tabPanel of brandTabListInstance.tabpanels) {
                  if (tabPanel.id !== "rt_tabpanel_free_search")
                    tabPanel.classList.add("rt-is-hidden");
                  else tabPanel.classList.remove("rt-is-hidden");
                }
                brandTabListInstance.tablistNode.style.display = "none";

                // searching logic
                const freeSearchListEl = document.getElementById(
                  "rt_free_search_list"
                )!;
                for (const brandEl of freeSearchListEl.children) {
                  if (
                    brandEl
                      .textContent!.toLowerCase()
                      .includes(brandFreeSearchInput.value)
                  ) {
                    (brandEl as HTMLElement).style.display = "";
                  } else {
                    (brandEl as HTMLElement).style.display = "none";
                  }
                }
              }
            }}
          />
          <button aria-label="search">
            <img
              src="/img/icon/search.svg"
              alt="search icon"
              aria-hidden="true"
            />
          </button>
        </div>
        <div class="rt-brands__tab">
          <div class="rt-tabs">
            <div class="rt-tablist" role="tablist">
              {alphabetLetters.map((pair, letterIndex) => (
                <>
                  {brandObjArray.some((brandObj) =>
                    brandObj.name.match(pair[0])
                  ) ? (
                    <button
                      id={`rt_tab_${pair[1]}`}
                      type="button"
                      role="tab"
                      aria-selected={letterIndex === 0 ? "true" : "false"}
                      aria-controls={`rt_tabpanel_${pair[1]}`}
                    >
                      <span>{pair[1]}</span>
                    </button>
                  ) : (
                    <button tabIndex="-1">
                      <span>{pair[1]}</span>
                    </button>
                  )}
                </>
              ))}
            </div>
            <div id="rt_brand_tabpanels" class="rt-tabpanels">
              {alphabetLetters.map((pair) => (
                <>
                  {brandObjArray.some((brandObj) =>
                    brandObj.name.match(pair[0])
                  ) && (
                    <div
                      id={`rt_tabpanel_${pair[1]}`}
                      role="tabpanel"
                      tabIndex="0"
                      aria-labelledby={`rt_tab_${pair[1]}`}
                    >
                      <span class="rt-tabpanels__letter">{pair[1]}</span>
                      <ol class="rt-tabpanels__results">
                        {brandObjArray
                          .filter((brandObj) => brandObj.name.match(pair[0]))
                          .map((matchedBrand) => (
                            <li>
                              <label class="rt-filters__option">
                                <input
                                  id={`rt_filter_item_${matchedBrand.filterParam}`}
                                  type="checkbox"
                                  onclick={(e: Event) => {
                                    const currentInputEl =
                                      e.currentTarget as HTMLInputElement;
                                    checkBoxEventHandler(
                                      currentInputEl,
                                      matchedBrand
                                    );
                                    // update other pair
                                    const freeSearchList =
                                      document.getElementById(
                                        "rt_free_search_list"
                                      )!;
                                    const freeSearchInputPair =
                                      freeSearchList.querySelector(
                                        `#rt_filter_item_${matchedBrand.filterParam}`
                                      ) as HTMLInputElement;
                                    freeSearchInputPair.checked =
                                      currentInputEl.checked;
                                  }}
                                  checked={appliedFilters.some(
                                    (af) =>
                                      af.filterParam ===
                                      matchedBrand.filterParam
                                  )}
                                />
                                <span>{matchedBrand.name}</span>
                              </label>
                            </li>
                          ))}
                      </ol>
                    </div>
                  )}
                </>
              ))}
              {/* <!-- ! free search tabpanel --> */}
              <div
                id="rt_tabpanel_free_search"
                role="tabpanel"
                tabIndex="0"
                aria-labelledby="rt_tab_free_search"
                class="rt-is-hidden"
              >
                <ol id="rt_free_search_list" class="rt-tabpanels__results">
                  {brandObjArray.map((brandObj) => (
                    <li style="display: none;">
                      <label class="rt-filters__option">
                        <input
                          id={`rt_filter_item_${brandObj.filterParam}`}
                          type="checkbox"
                          onclick={(e: Event) => {
                            const currentInputEl =
                              e.currentTarget as HTMLInputElement;
                            checkBoxEventHandler(currentInputEl, brandObj);
                            // update other pair
                            const tabPanelsContainer =
                              document.getElementById("rt_brand_tabpanels")!;
                            const ordinaryTabpanelInputPair =
                              tabPanelsContainer.querySelector(
                                `#rt_filter_item_${brandObj.filterParam}`
                              ) as HTMLInputElement;
                            ordinaryTabpanelInputPair.checked =
                              currentInputEl.checked;
                          }}
                          checked={appliedFilters.some(
                            (af) => af.filterParam === brandObj.filterParam
                          )}
                        />
                        <span>{brandObj.name}</span>
                      </label>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function updateAppliedFiltersSectionUI() {
  const appliedFiltersParentContainer = document.getElementById(
    "rt_filters_body_container"
  )!;
  const appliedFiltersContainer = document.getElementById(
    "rt_applied_filters_container"
  );
  appliedFiltersContainer?.remove();
  appliedFilters.length !== 0 &&
    appliedFiltersParentContainer.insertBefore(
      getAppliedFiltersSectionUI(retailtune.translations),
      appliedFiltersParentContainer.firstChild!
    );
}

let i = setInterval(() => {
  try {
    if (!google || !retailtune || !sendGa4DL)
      throw new Error("Inexisting required objects to load this page!");
    clearInterval(i);
    main();
  } catch (error) {
    console.warn(error);
  }
}, 50);
